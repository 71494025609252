import { Control, Controller, FieldError, UseFormRegister } from 'react-hook-form';
import TextField from 'ui/form/TextField';
import SelectField from 'ui/form/SelectField';
import { useEffect, useState } from 'react';
import { Option } from 'ui/components/form/SelectInput';
import { PowerDistributionUnitSelect } from 'powerDistributionUnits/models/powerDistributionUnit';
import { ConsumerUnitModalityType } from 'consumerUnits/model/consumerUnit';
import NumberOnlyField from 'ui/form/NumberOnlyField';
import CountryStateSelectField from 'ui/form/CountryStateSelectField';
import RadioField from 'ui/form/RadioField';
import omit from 'lodash/omit';
import { CooperativeMemberSelect } from 'cooperatives/models/cooperativeMember';

export type FormFields = {
  consumerUnitPowerDistributionUnitIdentifier: string;
  consumerUnitAddressState: string;
  consumerUnitAddressCity: string;
  consumerUnitAddressPostalCode: number;
  consumerUnitAddressStreet: string;
  consumerUnitAddressDistrict: string;
  consumerUnitAddressComplement?: string;
  consumerUnitConsumptionGroupType: string;
  powerDistributionUnitCredentialsUser: string;
  powerDistributionUnitCredentialsPasswordInput: string;
  consumerUnitModality: string;
  consumerUnitConsumptionClass: string;
  cooperativeId: number;
  cooperativeMemberId: number;
  powerDistributionUnitId: number;
};

export type FormErrors = {
  consumerUnitPowerDistributionUnitIdentifier?: FieldError | undefined;
  consumerUnitAddressState?: FieldError | undefined;
  consumerUnitAddressCity?: FieldError | undefined;
  consumerUnitAddressPostalCode?: FieldError | undefined;
  consumerUnitAddressStreet?: FieldError | undefined;
  consumerUnitAddressDistrict?: FieldError | undefined;
  consumerUnitAddressComplement?: FieldError | undefined;
  consumerUnitConsumptionGroupType?: FieldError | undefined;
  powerDistributionUnitCredentialsUser?: FieldError | undefined;
  powerDistributionUnitCredentialsPasswordInput?: FieldError | undefined;
  consumerUnitModality?: FieldError | undefined;
  consumerUnitConsumptionClass?: FieldError | undefined;
  cooperativeId?: FieldError | undefined;
  cooperativeMemberId?: FieldError | undefined;
  powerDistributionUnitId?: FieldError | undefined;
};

type Field =
  | 'consumerUnitPowerDistributionUnitIdentifier'
  | 'consumerUnitAddressState'
  | 'consumerUnitAddressCity'
  | 'consumerUnitAddressPostalCode'
  | 'consumerUnitAddressStreet'
  | 'consumerUnitAddressDistrict'
  | 'consumerUnitAddressComplement'
  | 'consumerUnitConsumptionGroupType'
  | 'powerDistributionUnitCredentialsUser'
  | 'powerDistributionUnitCredentialsPasswordInput'
  | 'consumerUnitModality'
  | 'consumerUnitConsumptionClass'
  | 'cooperativeId'
  | 'cooperativeMemberId'
  | 'powerDistributionUnitId';

export const FORM_FIELDS: Field[] = [
  'consumerUnitPowerDistributionUnitIdentifier',
  'consumerUnitAddressState',
  'consumerUnitAddressCity',
  'consumerUnitAddressPostalCode',
  'consumerUnitAddressStreet',
  'consumerUnitAddressDistrict',
  'consumerUnitAddressComplement',
  'consumerUnitConsumptionGroupType',
  'powerDistributionUnitCredentialsUser',
  'powerDistributionUnitCredentialsPasswordInput',
  'consumerUnitModality',
  'consumerUnitConsumptionClass',
  'cooperativeId',
  'cooperativeMemberId',
  'powerDistributionUnitId',
];

type consumerUnitModalityType = {
  key: string;
  value: string;
};

const EMPTY_POWER_DISTRIBUTION_UNIT_OPTION = {
  key: '',
  value: 'Escolha uma Concessionária',
};

const EMPTY_COOPERATIVE_MEMBER_OPTION = {
  key: '',
  value: 'Escolha um Cooperado',
};

const EMPTY_COOPERATIVE_OPTION = {
  key: '',
  value: 'Escolha uma Cooperativa',
};

const EMPTY_CONSUMER_UNIT_MODALITY_OPTION = {
  key: '',
  value: 'Escolha uma Modalidade',
};

const consumerUnitConsumptionClassOptions = [
  {
    key: '',
    value: 'Escolha uma classe de consumo',
  },
  {
    key: 'COMMERCIAL',
    value: 'COMMERCIAL',
  },
  {
    key: 'RESIDENTIAL',
    value: 'RESIDENTIAL',
  },
  {
    key: 'INDUSTRIAL',
    value: 'INDUSTRIAL',
  },
];

export default function ConsumerUnitFormFields({
  errors,
  register,
  consumerUnitModality,
  powerDistributionUnits,
  cooperativeMembers,
  cooperativeMemberValue,
  cooperativeValue,
  disableCooperativeValue,
  control,
  powerDistributionUnitValue,
  disableFields,
}: {
  errors: FormErrors;
  register: UseFormRegister<FormFields>;
  consumerUnitModality?: ConsumerUnitModalityType;
  powerDistributionUnits?: PowerDistributionUnitSelect[];
  powerDistributionUnitValue?: string;
  cooperativeMemberValue?: string;
  cooperativeValue?: string;
  disablePowerDistributionUnitSelect?: boolean;
  disableCooperativeValue?: boolean;
  disableCooperativeMemberValue?: boolean;
  cooperativeMembers?: CooperativeMemberSelect[];
  disableFields?: boolean;
  control: Control<FormFields>;
}) {
  const [powerDistributionUnitOptions, setPowerDistributionUnitOptions] = useState<
    Option[]
  >([]);
  useEffect(
    () =>
      setPowerDistributionUnitOptions([
        EMPTY_POWER_DISTRIBUTION_UNIT_OPTION,
        ...(powerDistributionUnits?.map((powerDistributionUnit) => ({
          key: `${powerDistributionUnit.id}`,
          value: powerDistributionUnit.powerDistributionUnitLegalName,
        })) || []),
      ]),
    [powerDistributionUnits]
  );

  const [cooperativeMemberOptions, setCooperativeMemberOptions] = useState<Option[]>(
    []
  );
  useEffect(
    () =>
      setCooperativeMemberOptions([
        EMPTY_COOPERATIVE_MEMBER_OPTION,
        ...(cooperativeMembers?.map((cooperativeMember) => ({
          key: `${cooperativeMember.id}`,
          value: cooperativeMember.cooperativeMemberLegalName,
        })) || []),
      ]),
    [cooperativeMembers]
  );

  const [consumerUnitModalities] = useState<consumerUnitModalityType[]>([
    { key: 'GREEN', value: 'GREEN' },
    { key: 'BLUE', value: 'BLUE' },
    { key: 'CONVENTIONAL', value: 'CONVENTIONAL' },
  ]);

  const [consumerUnitModalityOptions, setConsumerUnitModalityOptions] = useState<
    Option[]
  >([]);
  useEffect(
    () =>
      setConsumerUnitModalityOptions([
        EMPTY_CONSUMER_UNIT_MODALITY_OPTION,
        ...(consumerUnitModalities?.map((consumerUnitModality) => ({
          key: `${consumerUnitModality.key}`,
          value: consumerUnitModality.value,
        })) || []),
      ]),
    [consumerUnitModalities]
  );

  const [cooperative, setCooperative] = useState<{ id: string; value: string }>();

  const [cooperativeOptions, setCooperativeOptions] = useState<Option[]>([]);
  useEffect(
    () =>
      setCooperativeOptions([
        EMPTY_COOPERATIVE_OPTION,
        ...((cooperative && [{ key: cooperative.id, value: cooperative.value }]) ||
          []),
      ]),
    [cooperative]
  );

  const handleCooperative = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const cooperativeParams = cooperativeMembers?.find(
      (item) => item.id.toString() === e.target.value
    );
    cooperativeParams &&
      setCooperative({
        id: cooperativeParams?.cooperative.id.toString(),
        value: cooperativeParams?.cooperative.cooperativeLegalName,
      });
  };

  const isDisabledCooperative = !cooperative || !!disableCooperativeValue;

  useEffect(() => {
    const cooperativeParams = cooperativeMembers?.find(
      (item) => item.id.toString() === powerDistributionUnitValue?.toString()
    );

    cooperativeParams &&
      setCooperative({
        id: cooperativeParams?.cooperative.id.toString(),
        value: cooperativeParams?.cooperative.cooperativeLegalName,
      });
  }, [cooperativeMembers, powerDistributionUnitValue]);

  return (
    <>
      <div className="grid grid-cols-3 gap-8">
        <TextField
          error={errors?.consumerUnitPowerDistributionUnitIdentifier?.message}
          label="Número da UC"
          id={'consumerUnitPowerDistributionUnitIdentifier'}
          {...register('consumerUnitPowerDistributionUnitIdentifier', {
            required: true,
            disabled: disableFields,
          })}
          placeholder="Ex: 43213422"
          disabled={disableFields}
        />

        <SelectField
          error={errors.cooperativeMemberId?.message}
          label="Cooperado"
          id="cooperativeMemberId"
          {...register('cooperativeMemberId', {
            required: true,
            disabled: !!cooperativeMemberValue,
          })}
          options={cooperativeMemberOptions}
          onChange={(e) => handleCooperative(e)}
          value={cooperativeMemberValue}
          disabled={!!cooperativeMemberValue}
        />

        <SelectField
          error={errors.cooperativeId?.message}
          label="Cooperativa"
          id="cooperativeId"
          {...register('cooperativeId', {
            required: true,
            disabled: isDisabledCooperative,
          })}
          options={cooperativeOptions}
          disabled={isDisabledCooperative}
          value={cooperativeValue}
        />

        <SelectField
          error={errors.powerDistributionUnitId?.message}
          label="Concessionária"
          id="powerDistributionUnitId"
          {...register('powerDistributionUnitId', {
            required: true,
            disabled: !!powerDistributionUnitValue,
          })}
          options={powerDistributionUnitOptions}
          value={powerDistributionUnitValue}
          disabled={!!powerDistributionUnitValue}
        />
      </div>

      <p className="text-gray-dark400 font-bold text-sm pt-8 pb-6">Endereço</p>
      <div className="grid grid-cols-3 gap-8">
        <NumberOnlyField
          error={errors.consumerUnitAddressPostalCode?.message}
          label="CEP"
          id="consumerUnitAddressPostalCode"
          {...register('consumerUnitAddressPostalCode', {
            required: true,
            valueAsNumber: true,
          })}
          placeholder="81000000"
        />
        <CountryStateSelectField
          error={errors.consumerUnitAddressState?.message}
          label="Estado"
          id="consumerUnitAddressState"
          {...register('consumerUnitAddressState', {
            required: true,
          })}
        />
        <TextField
          error={errors.consumerUnitAddressCity?.message}
          label="Cidade"
          id="consumerUnitAddressCity"
          {...register('consumerUnitAddressCity', { required: true })}
          placeholder="Ex.: Curitiba"
        />
        <TextField
          error={errors.consumerUnitAddressDistrict?.message}
          label="Bairro"
          id="consumerUnitAddressDistrict"
          {...register('consumerUnitAddressDistrict', { required: true })}
          placeholder="Ex.: Centro"
        />
        <TextField
          error={errors.consumerUnitAddressStreet?.message}
          label="Endereço"
          id="consumerUnitAddressStreet"
          {...register('consumerUnitAddressStreet', { required: true })}
          placeholder="Ex.: Comendador Franco 777"
        />
        <TextField
          error={errors.consumerUnitAddressComplement?.message}
          label="Complemento"
          id="consumerUnitAddressComplement"
          {...register('consumerUnitAddressComplement', {
            required: false,
          })}
          placeholder="Ex.: Sala 1"
        />
      </div>

      <p className="text-gray-dark400 font-bold text-sm pt-8 pb-6">
        Informações Técnicas
      </p>

      <Controller
        name="consumerUnitConsumptionGroupType"
        control={control}
        rules={{ required: true }}
        render={({ field: { ref, onChange, value } }) => (
          <RadioField
            {...omit(ref, 'ref', 'value')}
            error={errors.consumerUnitConsumptionGroupType?.message}
            label="Grupo"
            id="consumerUnitConsumptionGroupType"
            options={[
              {
                label: 'Grupo A',
                value: 'A',
              },
              {
                label: 'Grupo B',
                value: 'B',
              },
            ]}
            onChange={onChange}
            value={value}
            disabled={disableFields}
          />
        )}
      />

      <div className="grid grid-cols-12 gap-8 mt-5">
        <div className="col-start-1 col-end-5">
          <SelectField
            error={errors.consumerUnitConsumptionClass?.message}
            label="Classe de Consumo"
            id="consumerUnitConsumptionClass"
            {...register('consumerUnitConsumptionClass', {
              required: true,
              disabled: disableFields,
            })}
            options={consumerUnitConsumptionClassOptions}
            disabled={disableFields}
          />
        </div>
        <div className="col-start-5 col-end-9">
          <SelectField
            error={errors.consumerUnitModality?.message}
            label="Modalidade"
            id="consumerUnitModality"
            {...register('consumerUnitModality', {
              required: true,
              disabled: !!consumerUnitModality,
            })}
            options={consumerUnitModalityOptions}
            value={consumerUnitModality}
            disabled={!!consumerUnitModality}
          />
        </div>
        <div className="col-start-1 col-end-5">
          <TextField
            error={errors.powerDistributionUnitCredentialsUser?.message}
            label="Usuário da Concessionária"
            id="powerDistributionUnitCredentialsUser"
            {...register('powerDistributionUnitCredentialsUser', { required: true })}
            placeholder="Ex.: exe@gmail.com ou 28.054.303/0001-06"
          />
        </div>
        <div className="col-start-5 col-end-9">
          <TextField
            error={errors.powerDistributionUnitCredentialsPasswordInput?.message}
            label="Senha da Concessionária"
            id="powerDistributionUnitCredentialsPasswordInput"
            {...register('powerDistributionUnitCredentialsPasswordInput', {
              required: !disableFields,
            })}
            placeholder="*******"
          />
        </div>
      </div>
    </>
  );
}
