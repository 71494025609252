import { MenuSidebar } from 'dashboard/models/navigation';
import {
  MdAccountBalance,
  MdHome,
  MdGroup,
  MdShowChart,
  MdLocalAtm,
  MdArrowBack,
  MdArrowForward,
  MdMoneyOff,
  MdFlashOn,
  MdPoll,
  MdStoreMallDirectory,
  MdWifiTethering,
} from 'react-icons/md';

import { ImHammer2 } from 'react-icons/im';

export const menuSidebar: MenuSidebar = [
  { name: 'Início', subtitle: 'INÍCIO', to: '/dashboard', icon: MdHome },
  {
    name: 'Gestão de Concessionária',
    subtitle: 'CONCESSIONÁRIA',
    icon: MdAccountBalance,
    submenu: [
      {
        name: 'Concessionárias',
        to: '/dashboard/power-distribution-units',
        icon: MdAccountBalance,
      },
      {
        name: 'Grupos tarifários',
        to: '/dashboard/power-distribution-units/tariff-groups',
        icon: MdLocalAtm,
      },
      {
        name: 'PIS/COFINS',
        to: '/dashboard/power-distribution-units/pis-cofins-taxes',
        icon: MdShowChart,
      },
    ],
  },
  {
    name: 'Gestão de Usuários',
    subtitle: 'GESTÃO DE USUÁRIOS',
    to: '/dashboard',
    icon: MdGroup,
    submenu: [
      {
        name: 'Usuário Interno',
        to: '/dashboard/internal-users',
        icon: MdArrowBack,
      },
      { name: 'Usuário Final', to: '/dashboard', icon: MdArrowForward },
    ],
  },
  {
    name: 'Gestão de Impostos',
    subtitle: 'TAXES & FEES MANAGEMENT',
    to: '/dashboard/admins',
    icon: ImHammer2,
    submenu: [
      {
        name: 'ICMS',
        to: '/dashboard/taxes-fees-management/icms-taxes',
        icon: MdMoneyOff,
      },
    ],
  },
  {
    name: 'Cooperativas',
    subtitle: 'COOPERATIVAS',
    to: '/dashboard/cooperatives',
    icon: MdFlashOn,
    submenu: [
      {
        name: 'Cooperativas',
        to: '/dashboard/cooperatives',
        icon: MdFlashOn,
      },
      {
        name: 'Membros da Cooperativa',
        to: '/dashboard/cooperatives/members',
        icon: MdFlashOn,
      },
    ],
  },
  {
    name: 'Usinas',
    subtitle: 'USINAS',
    to: '/dashboard',
    icon: MdWifiTethering,
  },
  {
    name: 'UCs',
    subtitle: 'UCs',
    to: '/dashboard/consumer-units',
    icon: MdStoreMallDirectory,
    submenu: [
      {
        name: 'Unidades Consumidoras',
        to: '/dashboard/consumer-units',
        icon: MdStoreMallDirectory,
      },
    ],
  },
  {
    name: 'Contabilidade',
    subtitle: 'CONTABILIDADE',
    to: '/dashboard',
    icon: MdPoll,
  },
];
