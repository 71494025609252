import SelectField from 'ui/form/SelectField';
import TextField from 'ui/form/TextField';
import { Control, Controller, FieldError, UseFormRegister } from 'react-hook-form';
import { CooperativeSelect } from 'cooperatives/models/cooperative';
import { CooperativeMemberEntityType } from 'cooperatives/models/cooperativeMember';
import { useEffect, useState } from 'react';
import { Option } from 'ui/components/form/SelectInput';
import RadioField from 'ui/form/RadioField';
import omit from 'lodash/omit';

export type FormFields = {
  cooperativeMemberLegalName: string;
  cooperativeMemberDocumentId: string;
  cooperativeMemberEntityType: CooperativeMemberEntityType;
  cooperativeId: number;
};

export type FormErrors = {
  cooperativeMemberLegalName?: FieldError | undefined;
  cooperativeMemberDocumentId?: FieldError | undefined;
  cooperativeMemberEntityType?: FieldError | undefined;
  cooperativeId?: FieldError | undefined;
};

const EMPTY_COOPERATIVE_OPTION = { key: '', value: 'Selecione uma Cooperativa' };

export default function CooperativeFormFields({
  cooperatives,
  cooperativeValue,
  errors,
  control,
  register,
}: {
  errors: FormErrors;
  register: UseFormRegister<FormFields>;
  cooperatives?: CooperativeSelect[];
  cooperativeValue?: string;
  control: Control<FormFields>;
}) {
  const [cooperativeOptions, setcooperativeOptions] = useState<Option[]>([]);
  useEffect(
    () =>
      setcooperativeOptions([
        EMPTY_COOPERATIVE_OPTION,
        ...(cooperatives?.map((cooperative) => ({
          key: `${cooperative.id}`,
          value: cooperative.cooperativeLegalName,
        })) || []),
      ]),
    [cooperatives]
  );

  return (
    <>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-start-1 col-end-4">
          <Controller
            name="cooperativeMemberEntityType"
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, onChange, value } }) => (
              <RadioField
                {...omit(ref, 'ref', 'value')}
                error={errors.cooperativeMemberEntityType?.message}
                label="Entidade"
                id="cooperativeMemberEntityType"
                options={[
                  {
                    label: 'Jurídica',
                    value: 'COMPANY',
                  },
                  {
                    label: 'Física',
                    value: 'INDIVIDUAL',
                  },
                ]}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </div>

        <div className="col-start-1 col-end-2">
          <TextField
            error={errors.cooperativeMemberLegalName?.message}
            label="Razão Social/Nome"
            id="cooperativeMemberLegalName"
            {...register('cooperativeMemberLegalName', { required: true })}
            placeholder="Ex.: Nex Energy Gestão de Energia"
          />
        </div>

        <div className="col-start-2 col-end-3">
          <TextField
            error={errors.cooperativeMemberDocumentId?.message}
            label="Documento"
            id="cooperativeMemberDocumentId"
            {...register('cooperativeMemberDocumentId', { required: true })}
            placeholder="Ex.: 28.054.303/0001-06"
          />
        </div>

        <div className="col-start-3 col-end-4">
          <SelectField
            error={errors.cooperativeId?.message}
            label="Cooperativa"
            id="cooperativeId"
            {...register('cooperativeId', {
              required: true,
            })}
            options={cooperativeOptions}
            value={cooperativeValue}
          />
        </div>
      </div>
    </>
  );
}
