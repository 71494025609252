import { Switch, Route } from 'react-router-dom';

import { LoginPage, NewPasswordPage } from 'auth/pages/login';
import {
  ListInternalUsersPage,
  CreateInternalUsersPage,
  EditInternalUsersPage,
} from 'internalUsers/pages';
import {
  ListPowerDistributionUnitsPage,
  CreatePowerDistributionUnitsPage,
  EditPowerDistributionUnitsPage,
  ListPowerDistributionUnitTariffGroups,
} from 'powerDistributionUnits/pages';
import {
  CreateCooperativesPage,
  ListCooperativesPage,
  EditCooperativesPage,
  ListCooperativeMembersPage,
  CreateCooperativeMembersPage,
} from 'cooperatives/pages';
import {
  ListPisCofinsTaxesPage,
  CreatePisCofinsTaxesPage,
  EditPisCofinsTaxesPage,
} from 'pisCofinsTaxes/pages';
import {
  ListIcmsTaxesPage,
  CreateIcmsTaxesPage,
  EditIcmsTaxesPage,
} from 'icmsTaxes/pages';
import { Dashboard } from 'dashboard/components/dashboard';
import {
  ListConsumerUnitsPage,
  CreateConsumerUnitsPage,
  EditConsumerUnitsPage,
} from 'consumerUnits/pages';

import PrivateRoute from './PrivateRoute';
import UiDashboard from './UiDashboard';

const Routes = () => (
  <Switch>
    <Route path="/dashboard/ui" component={UiDashboard} />
    <PrivateRoute
      path="/dashboard/internal-users/create"
      component={CreateInternalUsersPage}
    />
    <PrivateRoute
      path="/dashboard/internal-users/:id/edit"
      component={EditInternalUsersPage}
    />
    <PrivateRoute
      path="/dashboard/internal-users"
      component={ListInternalUsersPage}
    />
    <PrivateRoute
      path="/dashboard/taxes-fees-management/icms-taxes/create"
      component={CreateIcmsTaxesPage}
    />
    <PrivateRoute
      path="/dashboard/taxes-fees-management/icms-taxes/:id/edit"
      component={EditIcmsTaxesPage}
    />
    <PrivateRoute
      path="/dashboard/taxes-fees-management/icms-taxes"
      component={ListIcmsTaxesPage}
    />
    <PrivateRoute
      path="/dashboard/power-distribution-units/pis-cofins-taxes/create"
      component={CreatePisCofinsTaxesPage}
    />
    <PrivateRoute
      path="/dashboard/power-distribution-units/pis-cofins-taxes/:id/edit"
      component={EditPisCofinsTaxesPage}
    />

    <PrivateRoute
      path="/dashboard/power-distribution-units/pis-cofins-taxes"
      component={ListPisCofinsTaxesPage}
    />
    <PrivateRoute
      path="/dashboard/power-distribution-units/create"
      component={CreatePowerDistributionUnitsPage}
    />
    <PrivateRoute
      path="/dashboard/power-distribution-units/:id/edit"
      component={EditPowerDistributionUnitsPage}
    />
    <PrivateRoute
      path="/dashboard/cooperatives/create"
      component={CreateCooperativesPage}
    />
    <PrivateRoute
      path="/dashboard/cooperatives/:id/edit"
      component={EditCooperativesPage}
    />
    <PrivateRoute
      path="/dashboard/cooperatives/members/create"
      component={CreateCooperativeMembersPage}
    />
    <PrivateRoute
      path="/dashboard/cooperatives/members"
      component={ListCooperativeMembersPage}
    />

    <PrivateRoute
      path="/dashboard/power-distribution-units/tariff-groups"
      component={ListPowerDistributionUnitTariffGroups}
    />
    <PrivateRoute
      path="/dashboard/consumer-units/create"
      component={CreateConsumerUnitsPage}
    />
    <PrivateRoute
      path="/dashboard/consumer-units/:id/edit"
      component={EditConsumerUnitsPage}
    />
    <PrivateRoute
      path="/dashboard/consumer-units"
      component={ListConsumerUnitsPage}
    />
    <PrivateRoute
      path="/dashboard/power-distribution-units"
      component={ListPowerDistributionUnitsPage}
    />
    <PrivateRoute path="/dashboard/cooperatives" component={ListCooperativesPage} />
    <PrivateRoute path="/dashboard" component={Dashboard} />
    <Route path="/sign-in/new-password" component={NewPasswordPage} />
    <Route path="/" component={LoginPage} />
  </Switch>
);

export default Routes;
