import { FieldError, UseFormRegister, Control, Controller } from 'react-hook-form';
import TextField from 'ui/form/TextField';
import SelectField from 'ui/form/SelectField';
import DatePickerField from 'ui/form/DatePickerField';
import { useEffect, useState } from 'react';
import { Option } from 'ui/components/form/SelectInput';
import {
  PowerDistributionUnitSelect,
  PowerDistributionUnitId,
} from 'powerDistributionUnits/models/powerDistributionUnit';

export type FormFields = {
  pisCofinsValidityStartAt: Date;
  pisCofinsValidityEndAt: Date;
  pisCofinsPisFee: string;
  pisCofinsCofinsFee: string;
  powerDistributionUnitId: PowerDistributionUnitId;
};

export type FormFieldsQuery = {
  pisCofinsValidityStartAt: string;
  pisCofinsValidityEndAt: string;
  pisCofinsPisFee: string;
  pisCofinsCofinsFee: string;
  powerDistributionUnitId: PowerDistributionUnitId;
};

export type FormErrors = {
  pisCofinsValidityStartAt?: FieldError | undefined;
  pisCofinsValidityEndAt?: FieldError | undefined;
  pisCofinsPisFee?: FieldError | undefined;
  pisCofinsCofinsFee?: FieldError | undefined;
  powerDistributionUnitId?: FieldError | undefined;
};

type Field =
  | 'pisCofinsValidityStartAt'
  | 'pisCofinsValidityEndAt'
  | 'pisCofinsPisFee'
  | 'pisCofinsCofinsFee'
  | 'powerDistributionUnitId';

export const FORM_FIELDS: Field[] = [
  'pisCofinsValidityStartAt',
  'pisCofinsValidityEndAt',
  'pisCofinsPisFee',
  'pisCofinsCofinsFee',
  'powerDistributionUnitId',
];

const EMPTY_POWER_DISTRIBUTION_UNIT_OPTION = {
  key: '',
  value: 'Selecione uma Concessionária',
};

export default function PixCofinsTaxFormFields({
  errors,
  register,
  control,
  powerDistributionUnits,
  powerDistributionUnitValue,
  disablePowerDistributionUnitSelect,
}: {
  errors: FormErrors;
  register: UseFormRegister<FormFields>;
  control: Control<FormFields>;
  powerDistributionUnits?: PowerDistributionUnitSelect[];
  powerDistributionUnitValue?: string;
  disablePowerDistributionUnitSelect?: boolean;
}) {
  const [powerDistributionUnitOptions, setPowerDistributionUnitOptions] = useState<
    Option[]
  >([]);
  useEffect(
    () =>
      setPowerDistributionUnitOptions([
        EMPTY_POWER_DISTRIBUTION_UNIT_OPTION,
        ...(powerDistributionUnits?.map((powerDistributionUnit) => ({
          key: `${powerDistributionUnit.id}`,
          value: powerDistributionUnit.powerDistributionUnitLegalName,
        })) || []),
      ]),
    [powerDistributionUnits]
  );

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-start-1 col-end-4">
        <SelectField
          error={errors.powerDistributionUnitId?.message}
          label="Concessionária"
          id="powerDistributionUnitId"
          {...register('powerDistributionUnitId', {
            required: true,
            disabled: disablePowerDistributionUnitSelect,
          })}
          options={powerDistributionUnitOptions}
          value={powerDistributionUnitValue}
          disabled={disablePowerDistributionUnitSelect}
        />
      </div>

      <div className="col-start-4 col-end-6">
        <TextField
          error={errors?.pisCofinsPisFee?.message}
          label="PIS"
          id={'pisCofinsPisFee'}
          {...register('pisCofinsPisFee', { required: true })}
          placeholder="% 0.00"
          maxLength={4}
        />
      </div>

      <div className="col-start-6 col-end-8">
        <TextField
          error={errors?.pisCofinsCofinsFee?.message}
          label="COFINS"
          id="pisCofinsCofinsFee"
          {...register('pisCofinsCofinsFee', {
            required: true,
          })}
          placeholder="% 0.00"
          maxLength={4}
        />
      </div>

      <div className="col-start-8 col-end-10">
        <Controller
          render={({ field: { onChange, value } }) => (
            <DatePickerField
              error={errors.pisCofinsValidityStartAt?.message}
              id="pisCofinsValidityStartAt"
              {...register('pisCofinsValidityStartAt', {
                required: true,
              })}
              labelDate="Inicio da validade"
              onChange={onChange}
              value={value}
            />
          )}
          name="pisCofinsValidityStartAt"
          control={control}
          rules={{ required: true }}
        />
      </div>
      <div className="col-start-10 col-end-12">
        <Controller
          render={({ field: { onChange, value } }) => (
            <DatePickerField
              error={errors.pisCofinsValidityEndAt?.message}
              id="pisCofinsValidityEndAt"
              {...register('pisCofinsValidityEndAt', {
                required: true,
              })}
              labelDate="Fim da validade"
              onChange={onChange}
              value={value}
            />
          )}
          name="pisCofinsValidityEndAt"
          control={control}
          rules={{ required: true }}
        />
      </div>
    </div>
  );
}
